import React from 'react'

const Profile = () => {
  return (
    <div>
        <div className='profile-text'>
            CHEONG FUNG WEI
        </div>
    </div>
  )
}

export default Profile